:root {
  --color-primary: "#00321E";
  --color-secondray: "";
  --color-alert-25: "#F7E0E0";
  --color-alert-50: "#EBB1B1";
  --color-alert-100: "#D86464";
  --color-warning-25: "#FFF0CC";
  --color-warning-50: "#FFD97F";
  --color-warning-100: "#FBBC44";
  --color-success-25: "#E2F6E8";
  --color-success-50: "#B5E8C4";
  --color-success-100: "#6CD28B";
  --color-white: "#FFFFFF";
  --color-black: "#070707";
  --color-grey-light: "#f6f6f6";
  --color-grey-medium: "#CCCCCC";
  --color-grey-dark: "#808080";
}
