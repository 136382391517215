$mobile-breakpoint-1: 1140px; // 1440px;
$mobile-breakpoint-2: 900px; //1187px;
$mobile-breakpoint-3: 700px;
$footer-height: 96px;

:root {
  --mobile-breakpoint-1: var(--mobile-breakpoint-1);
  --mobile-breakpoint-2: var(--mobile-breakpoint-2);
}

:export {
  footer-height: $footer-height;
  mobile-breakpoint-1: $mobile-breakpoint-1;
  mobile-breakpoint-2: $mobile-breakpoint-2;
  mobile-breakpoint-3: $mobile-breakpoint-3;
}
