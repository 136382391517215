:root {
  --spacing-unit: 16px;
  --spacing-xxs: calc(0.25 * var(--spacing-unit));
  --spacing-xs: calc(0.5 * var(--spacing-unit));
  --spacing-sm: calc(0.75 * var(--spacing-unit));
  --spacing-md: calc(1 * var(--spacing-unit));
  --spacing-lg: calc(2 * var(--spacing-unit));
  --spacing-xl: calc(3 * var(--spacing-unit));
  --spacing-xxl: calc(4 * var(--spacing-unit));
  --spacing-xxxl: calc(6 * var(--spacing-unit));
}

.margin-top,
.margin-top--md {
  margin-top: var(--space-md);
}

.margin-top--xxs {
  margin-top: var(--space-xxs);
}

.margin-top--xs {
  margin-top: var(--space-xs);
}

.margin-top--sm {
  margin-top: var(--space-sm);
}

.margin-top--lg {
  margin-top: var(--space-lg);
}

.margin-top--xl {
  margin-top: var(--space-xl);
}

.margin-top--xxl {
  margin-top: var(--space-xxl);
}

.margin-top--none {
  margin-top: 0;
}

.margin-bottom,
.margin-bottom--md {
  margin-bottom: var(--space-md);
}

.margin-bottom--xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom--xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom--sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom--lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom--xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom--xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom--none {
  margin-bottom: 0;
}

.margin-right,
.margin-right--md {
  margin-right: var(--space-md);
}

.margin-right--xxs {
  margin-right: var(--space-xxs);
}

.margin-right--xs {
  margin-right: var(--space-xs);
}

.margin-right--sm {
  margin-right: var(--space-sm);
}

.margin-right--lg {
  margin-right: var(--space-lg);
}

.margin-right--xl {
  margin-right: var(--space-xl);
}

.margin-right--xxl {
  margin-right: var(--space-xxl);
}

.margin-right--none {
  margin-right: 0;
}

.margin-left,
.margin-left--md {
  margin-left: var(--space-md);
}

.margin-left--xxs {
  margin-left: var(--space-xxs);
}

.margin-left--xs {
  margin-left: var(--space-xs);
}

.margin-left--sm {
  margin-left: var(--space-sm);
}

.margin-left--lg {
  margin-left: var(--space-lg);
}

.margin-left--xl {
  margin-left: var(--space-xl);
}

.margin-left--xxl {
  margin-left: var(--space-xxl);
}

.margin-left--none {
  margin-left: 0;
}
