@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 700;
  src: url("../assets/fonts/BrandonGrotesque-Bold.otf") format("opentype");
}

@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 500;
  src: url("../assets/fonts/BrandonGrotesque-Medium.otf") format("opentype");
}

@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 400;
  src: url("../assets/fonts/BrandonGrotesque-Regular.otf") format("opentype");
}

:root {
  --font-primary: "BrandonGrotesque", Arial;
}

* {
  font-family: var(--font-primary) !important;
}
