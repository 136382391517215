@import "./constants.scss";

:root {
  --text-base-size: 18px;
  --text-scale: 1.33rem;

  --text-xs: 0.563rem;
  --text-sm: 0.7rem;
  --text-ssm: 0.9rem;
  --text-sssm: 0.999rem;
  --text-md: 1.16rem;
  --text-lg: 2.369rem;
  --text-xl: 3.157rem;

  --heading-line-height: var(--text-scale);
  --body-line-height: 24px;

  @media (max-width: $mobile-breakpoint-1) {
    --text-lg: 1.77rem;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  line-height: var(--heading-line-height);
}

h1 {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 43px;
  line-height: 56px;
}

h2 {
  font-family: var(--font-primary);
  font-size: 32px;
  line-height: 46px;
  font-weight: 500;
}

h3 {
  font-family: var(--font-primary);
  font-size: 21px;
  line-height: 32px;
  font-weight: 500;
}

.text-button {
  font-family: var(--font-primary);
  font-size: 18px;
  line-height: 18px;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  font-weight: 400;
  color: var(--color-text);
  line-height: 24px;
}
.button-style {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: var(--text-base-size);
  color: var(--color-black);
}
.description {
  font-size: var(--text-ssm);
  font-weight: 400;
}
.description-highlight {
  font-weight: 700;
  font-size: var(--text-sm);
}

small {
  font-size: var(--text-xs);
}

button,
.button {
  font-size: var(--text-base-size);
  line-height: 1;
}

.text--xl {
  font-size: var(--text-xl);
}

.text--lg {
  font-size: var(--text-lg);
}

.text--md {
  font-size: var(--text-md);
}

.text--ssm {
  font-size: var(--text-ssm);
}

.text--sm {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}

h5 {
  font-size: var(--text-base-size);
}

p {
  line-height: var(--body-line-height);
  margin: 0px;
}

a {
  color: var(--color-link);
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal !important;
}
