@import "./styles/index.scss";

body {
  margin: 0;
}

.root-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-white);
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 700;
  src: url("./assets/fonts/BrandonGrotesque-Bold.otf") format("opentype");
}

@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 500;
  src: url("./assets/fonts/BrandonGrotesque-Medium.otf") format("opentype");
}

@font-face {
  font-family: "BrandonGrotesque";
  font-display: auto;
  font-weight: 400;
  src: url("./assets/fonts/BrandonGrotesque-Regular.otf") format("opentype");
}

:root {
  --font-primary: "BrandonGrotesque", Arial;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.dmbackground {
  &.infinite {
    .path1 {
      animation: enter 1.5s infinite alternate-reverse;
    }
    .path2 {
      animation: enter 1.5s 0.05s infinite alternate-reverse;
    }
    .path3 {
      animation: enter 1.5s 0.1s infinite alternate-reverse;
    }
    .path4 {
      animation: enter 1.5s 0.15s infinite alternate-reverse;
    }
    .path5 {
      animation: enter 1.5s 0.2s infinite alternate-reverse;
    }
    .path6 {
      animation: enter 1.5s 0.25s infinite alternate-reverse;
    }
    .path7 {
      animation: enter 1.5s 0.3s infinite alternate-reverse;
    }
    .path8 {
      animation: enter 1.5s 0.35s infinite alternate-reverse;
    }
    .path9 {
      animation: enter 1.5s 0.4s infinite alternate-reverse;
    }
    .path10 {
      animation: enter 1.5s 0.45s infinite alternate-reverse;
    }
  }
  path {
    opacity: 1;
    transform: scale(0);
    animation: enter 0.9s forwards;
    transform-origin: 45% 40%;
  }
  .path1 {
    animation-delay: 0s;
  }
  .path2 {
    animation-delay: 0.05s;
  }
  .path3 {
    animation-delay: 0.1s;
  }
  .path4 {
    animation-delay: 0.15s;
  }
  .path5 {
    animation-delay: 0.2s;
  }
  .path6 {
    animation-delay: 0.25s;
  }
  .path7 {
    animation-delay: 0.3s;
  }
  .path8 {
    animation-delay: 0.35s;
  }
  .path9 {
    animation-delay: 0.4s;
  }
  .path10 {
    animation-delay: 0.45s;
  }
}
